import axios from 'axios'
import React, { useState, useEffect, useCallback } from 'react'
import { useLangContext } from '../context/lang.context'
import { s, sInput, sInputError, sFormTextBottom } from '../style'
import { validateEmail } from '../utils'
import Button from './Button'
import { sContainerNoTypes, sFormContainer } from './FormDynamic'
import FormFeedback from './FormFeedback'
import FormAgreements from './FormAgreements'
import Grid from './Grid'
import Main from './Main'

const content = {
  title: {
    pl: 'Newsletter',
    en: 'Newsletter',
    ua: 'Бюлетень',
  },
  desc: {
    pl: 'Zapisz się i zyskaj więcej. Dołącz do subskrybentów newslettera Omida Sea And Air. Bądź na bieżąco z aktualnościami i ofertami TSL.',
    en: 'Subscribe and gain more. Join the subscribers of the Omida Sea And Air newsletter. Stay up to date with news and TSL offers.',
    ua: 'Підпишіться та отримуйте більше. Приєднуйтесь до підписників інформаційного бюлетеня Omida Sea And Air. Будьте в курсі новин і пропозицій TSL.',
  },
  success: {
    title: { pl: 'Potwierdź subskrypcję!', en: 'Confirm the subscription!', ua: 'Підтвердьте підписку!', },
    text: {
      pl: 'Jeszcze jeden krok! Na podany adres email przesłaliśmy email z potwierdzeniem zapisu do newslettera. Jeżeli nie widzisz wiadomości, sprawdź skrzynkę spam.',
      en: 'Just one more step! We have sent you an email with subscription confirmation. Cannot see the message? Check the spam folder.',
      ua: 'Ще один крок! На вказану адресу електронної пошти ми надіслали електронний лист із підтвердженням підписки на розсилку. Якщо ви не бачите повідомлення, перевірте папку для спаму.',
    },
  },
  failure: {
    title: { pl: 'Niepowodzenie!', en: 'Failure!', ua: 'Провал!', },
    text: {
      pl: 'Wygląda na to, że Twoja wiadomość nie została wysłana. Skontaktuj się z nami drogą mailową lub telefonicznie',
      en: 'It looks like your message has not been sent. Contact us by e-mail or call us',
      ua: 'Схоже, ваше повідомлення не надіслано. Звяжіться з нами електронною поштою або по телефону',
    },
  },
  already: {
    title: { pl: 'Subskrybujesz!', en: 'Subscribing!', ua: 'Ви підписуєтеся!', },
    text: {
      pl: 'Twój adres email znajduje się na liście subskrybentów newslettera Omida. Niebawem najnowsze informacje pojawią się na Twojej skrzynce pocztowej!',
      en: 'Your email address has already been added to the Omida newsletter subscribers. The newest posts will appear on your mailbox soon!',
      ua: 'Ваша адреса електронної пошти є в списку підписників на розсилку Omida. Скоро актуальна інформація зявиться у вашій скриньці!',
    },
  },
  dictonary: {
    fill: {
      pl: 'Podaj email',
      en: 'Enter email',
      ua: 'Введіть свій email',
    },
    send: {
      pl: 'Subskrybuj',
      en: 'Subscribe',
      ua: 'Підпишіться',
    },
    fillagreements: {
      pl: 'Zaznacz zgodę',
      en: 'Agree to the terms',
      ua: 'Виберіть згоду',
    },
  },
  agreements: [
    {
      pl: '* Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji handlowych w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną o treści pochodzących od Omida Sea And Air S.A.',
      en: '* I agree to receive by e-mail commercial information coming from Omida Sea And Air S.A. within the meaning of the Act of 18 July 2002 on electronic services',
      ua: '* Я погоджуюся отримувати комерційну інформацію електронними засобами відповідно до Закону від 18 липня 2002 року про надання електронних послуг із вмістом Omida Sea And Air S.A.',
    },
    {
      pl: 'Wyrażam zgodę na wykorzystywanie przez Omida Sea And Air S.A. telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących celem przedstawienia informacji handlowych w rozumieniu przepisów ustawy z dnia 16 lipca 2014 r. - Prawo telekomunikacyjne',
      en: 'I hereby consent to the use by Omida Sea And Air S.A. of telecommunications terminal equipment and automated calling systems to present commercial information within the meaning of the Act of 16 July 2014. -Telecommunications Law',
      ua: 'Я даю згоду на використання компанією Omida Sea And Air S.A. телекомунікаційних кінцевих пристроїв і систем автоматичного виклику з метою надання комерційної інформації в розумінні положень Закону від 16 липня 2014 року - Закон про телекомунікації',
    },
  ],
  administrating: {
    pl: 'Administratorem Państwa danych osobowych będzie Omida Sea And Air Spółka Akcyjna (dalej: Sea And Air S.A). Można się z nami skontaktować w następujący sposób: listownie na adres: ul. Plac Kaszubski 8, 81-350 Gdynia, przez e-mail:',
    en: 'The administrator of your personal data will be Omida Sea And Air Spółka Akcyjna (hereinafter: Sea And Air S.A). You can contact us as follows: by letter to: ul. Plac Kaszubski 8, 81-350 Gdynia, by e-mail:',
    ua: 'Адміністратором ваших персональних даних буде Omida Sea And Air Spółka Akcyjna (далі: Sea And Air S.A). Ви можете звязатися з нами наступним чином: листом на адресу: ul. Plac Kaszubski 8, 81-350 Gdynia, електронною поштою:',
  },
  administratingSecond: {
    pl: '. Dane osobowe będziemy przetwarzać w celu przygotowania ofert dotyczących produktów i usług Omida. Szczegółowe informacje na temat przetwarzania danych osobowych znajdziesz',
    en: '. Personal data will be processed in order to prepare offers on Omida products and services. Details about the processing of your personal data are available',
    ua: '. Ми оброблятимемо персональні дані, щоб підготувати пропозиції щодо продуктів і послуг Omida. Детальну інформацію про обробку персональних даних можна знайти тут ',
  },
  administratingLinkText: {
    pl: 'tutaj',
    en: 'here',
    ua: 'тут',
  },
}

const FormNewsletter = () => {
  const { lang } = useLangContext()
  const [email, setEmail] = useState('')
  const [mailState, setMailState] = useState('unfilled')
  const [success, setSuccess] = useState(null)
  const [openBackdrop, setOpenBackdrop] = useState(false)
  const [wasSent, setWasSent] = useState(false)
  const initialFormAgreements = content.agreements.map((e) => false)
  const [formAgreements, setFormAgreements] = useState(initialFormAgreements)
  const agreementFilled = formAgreements[0]
  const [code, setCode] = useState(0)

  const validate = useCallback(() => {
    return validateEmail(email) && agreementFilled
  }, [email, agreementFilled])

  useEffect(() => {
    if (validate()) setMailState('filled')
    else setMailState('unfilled')
  }, [validate, setMailState])

  const onSend = (e) => {
    setSuccess(null)
    e.preventDefault()
    setWasSent(true)
    if (validate()) {
      setSuccess(null)
      setOpenBackdrop(true)
      setMailState('loading')
      axios({
        method: 'POST',
        url:
          process.env.NODE_ENV === 'development'
            ? 'http://localhost:5000/seaandair/newsletter/init'
            : 'https://cleverserver.appclever.pl/seaandair/newsletter/init',
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin': '*',
        },
        data: {
          email,
          campaignToken: 'QVklW',
          lang,
        },
      })
        .then((res) => {
          setSuccess(true)
          setEmail('')
          setOpenBackdrop(true)
          setWasSent(false)
          setCode(res.data.code)
          setFormAgreements([false])
        })
        .catch((error) => {
          console.log(error)
          setOpenBackdrop(true)
          setSuccess(false)
        })
    }
  }

  return (
    <>
      <Main
        h={2}
        s={1}
        title={content.title[lang]}
        desc={content.desc[lang]}
        subtitle={'SEA & AIR'}
      >
        <form onSubmit={onSend} css={sContainerNoTypes}>
          <Grid
            container
            ccss={[
              sFormContainer,
              {
                marginBottom: '4rem',
                marginTop: 0,
                [s.sm_down]: { marginBottom: '1rem' },
              },
            ]}
          >
            <Grid item xs={12}>
              <input
                type='email'
                name='email'
                placeholder='Email *'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                css={[
                  sInput,
                  wasSent && !validateEmail(email) && sInputError,
                  { marginBottom: 0, [s.md]: { marginBottom: 0 } },
                ]}
              />
            </Grid>
          </Grid>
          <FormAgreements
            data={content.agreements}
            formAgreements={formAgreements}
            setFormAgreements={setFormAgreements}
            wasSent={wasSent}
          />
          <div css={sFormTextBottom}>
            <p>
              {content.administrating[lang]}{' '}
              <a href='mailto:iodo@seaandair.pl'>iodo@seaandair.pl</a>
              {content.administratingSecond[lang]}{' '}
              <strong>
                <a
                  href={'/company/data-protection/'}
                  target='_blank'
                  rel='noreferrer'
                >
                  {content.administratingLinkText[lang]}
                </a>
              </strong>
              .
            </p>
          </div>
          <Button
            noMargin
            center
            reactiveBlack
            active={wasSent ? validateEmail(email) : true}
            googleId={'send_button'}
            extraCss={{
              marginTop: '4rem',
              [s.sm_down]: { marginTop: '2rem' },
            }}
            action={onSend}
          >
            {wasSent && mailState !== 'filled'
              ? !agreementFilled
                ? content.dictonary.fillagreements[lang]
                : content.dictonary.fill[lang]
              : content.dictonary.send[lang]}
          </Button>
        </form>
      </Main>
      {openBackdrop && (
        <FormFeedback
          success={success}
          open={openBackdrop}
          setOpen={setOpenBackdrop}
          dataSuccess={code === 2 ? content.already : content.success}
          dataFailure={content.failure}
        />
      )}
    </>
  )
}

export default FormNewsletter

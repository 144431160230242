import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import { seo, intro, main, branches } from '../content/kontakt'
import { useLangContext } from '../context/lang.context'
import { useFormContext } from '../context/form.context'
import Intro from '../components/Intro'
import Main from '../components/Main'
import PhoneIcon from '../assets/icons/phoneIcon'
import EmailIcon from '../assets/icons/emailIcon'
import Button from '../components/Button'
import Collapses from '../components/Collapses'
import { s } from '../style'
import FormSectionMessage from '../sections/forms/FormSectionMessage'
import Map from '../components/Map'
import FormNewsletter from '../components/FormNewsletter'

const Kontakt = () => {
  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const { image } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/kontakt.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FIXED
            quality: 100
            placeholder: BLURRED
            formats: [JPG]
          )
        }
      }
    }
  `)

  const button = {
    text: {
      pl: 'Wyślij zapytanie',
      en: 'Send Message',
      ua: 'НАДІСЛАТИ ЗАПИТ',
    },
    action: () => setFormOpen(true),
  }

  const buttonSecond = {
    text: {
      pl: 'Szukaj oddziału',
      en: 'Find an office',
      ua: 'ЗНАЙТИ ВІДДІЛЕННЯ',
    },
    action: 'SCROLL',
  }

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        url={seo.url}
        keywords={seo.keywords}
      />
      <Intro
        data={{ ...intro, button, buttonSecond }}
        image={image.childImageSharp}
      />
      <Main h={1} title={main.title[lang]} desc={main.desc[lang]}>
        <div css={sButtons}>
          <Button link={'tel:+48587723900'}>
            <div css={sButtonInside}>
              <PhoneIcon />
              +48 58 772 39 00
            </div>
          </Button>
          <div css={sButtonSpacing} />
          <Button link={'mailto:biuro@seaandair.pl'}>
            <div css={sButtonInside}>
              <EmailIcon />
              biuro@seaandair.pl
            </div>
          </Button>
        </div>
        <Collapses data={branches} onlyIconClick light />
      </Main>
      <Map />
      <FormSectionMessage />
      <FormNewsletter />
    </Layout>
  )
}

const sButtons = {
  display: 'flex',
  margin: '4rem 0',
  [s.sm_down]: {
    margin: '2rem 0',
    flexDirection: 'column',
  },
  [s.md]: {
    marginBottom: '8rem',
  },
}

const sButtonInside = {
  display: 'flex',
  alignItems: 'center',
  svg: {
    width: 24,
    height: 24,
    marginRight: '1rem',
  },
}

const sButtonSpacing = {
  marginLeft: '4rem',
  [s.sm_down]: {
    marginTop: '2rem',
  },
}

export default Kontakt
